:root {
    --primary-font-family: 'Courier New', Courier, monospace;
    
}

#root {
    height: 100vh;
}

* {
    font-family: var(--primary-font-family);
    margin: 0;
    padding: 0;
}

body {
    height: 100vh;
}

.app {
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.app p:first-child {
    margin-bottom: 10px;
}

.app .brain-svg {
    width: 2em;
    height: 2em;
}